'use client';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import {
  ContentSectionFragment,
  TextAndMedia as TextAndMediaType,
} from '@graphql/generated-contentful/graphql';
import { useCookieSettings } from '@lib/hooks/useCookieSettings';
import { toNonNullable } from '@lib/util/toNonNullable';
import { ImageFocalPoint } from '@ui-components/lib/types';
import { useEffect, useState } from 'react';
import { MediaAlignment, TextAndMedia } from 'ui-components';

type ContentSectionProps = {
  data: ContentSectionFragment;
};

const getMediaAlignment = (
  currentModulesWithMediaInARow,
  module: TextAndMediaType
): MediaAlignment => {
  const hasImage = module?.image !== null;
  const hasVideo = module?.videoUrl !== null;
  const hasMedia = hasImage || hasVideo;
  let mediaAlignment: MediaAlignment = 'right';

  if (hasMedia) {
    const EVEN_ODD_CHECKER = 2;
    const isOdd = currentModulesWithMediaInARow % EVEN_ODD_CHECKER;
    mediaAlignment = isOdd ? 'left' : 'right';
  }

  return mediaAlignment;
};

const RenderTextAndMedia = (
  module: TextAndMediaType,
  mediaAlignment: MediaAlignment,
  mediaFocalPoint: ImageFocalPoint | null,
  videoEmbed: string | null,
  cookieConsent: boolean
) => {
  if (!module?.body?.json) {
    return null;
  }

  return (
    <TextAndMedia
      key={module.title}
      title={module.title}
      text={documentToHtmlString(module.body?.json)}
      image={{
        url: module.image?.url ?? '',
        alt: module.image?.title ?? '',
        height: toNonNullable(module.image?.height),
        width: toNonNullable(module.image?.width),
      }}
      imageFocusArea={mediaFocalPoint}
      mediaAlignment={mediaAlignment}
      video={
        module?.videoUrl
          ? {
              title: module.videoTitle ?? '',
              embedUrl: videoEmbed ?? '',
              cookieConsent,
            }
          : null
      }
      className="mb-14"
    />
  );
};

const ContentSection = (props: ContentSectionProps) => {
  const { textAndMediaCollection } = props.data;
  const modules = textAndMediaCollection?.items ?? [];
  const [host, setHost] = useState<string | null>(null);
  const { functionalCategoryEnabled, targetingCategoryEnabled } =
    useCookieSettings();
  const cookieConsent = targetingCategoryEnabled && functionalCategoryEnabled;
  let mediaAlignment: MediaAlignment = 'right';
  let currentModulesWithMediaInARow = 0;

  useEffect(() => {
    setHost(window.location.host);
  }, []);

  return modules
    ?.filter((module) => module?.body?.json)
    .map((module) => {
      if (!module?.body?.json) {
        return null;
      }

      let videoEmbed = module?.videoUrl ?? null;

      // add location host to twitch embed url
      if (module?.videoProvider === 'twitch') {
        videoEmbed = `${module.videoUrl}&parent=${host}&autoplay=false`;
      }

      if (module?.image || module?.videoUrl) {
        mediaAlignment = getMediaAlignment(
          currentModulesWithMediaInARow,
          module as TextAndMediaType
        );

        currentModulesWithMediaInARow++;
      }

      return RenderTextAndMedia(
        module as TextAndMediaType,
        mediaAlignment,
        module.imageFocusArea,
        videoEmbed,
        cookieConsent
      );
    });
};

export default ContentSection;
