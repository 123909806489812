import { BlocksTitle } from '@components/Blocks/shared/BlocksTitle';
import { Scalars } from '@graphql/generated-contentful/graphql';
import { Maybe } from '@lib/types/Maybe';
import c from 'classnames';
import { ArrowRight } from 'phosphor-icons';

export const ProductRowHeading = ({
  title,
  description,
  text,
  link,
  onLinkClick,
}: {
  title: Maybe<string>;
  description?: Scalars['JSON']['output'];
  text?: Scalars['JSON']['output'];
  link?: { href: string; label: string };
  onLinkClick?: (label: string) => void;
}) => (
  <div
    className={c('container flex justify-end', {
      'mb-8': title || description,
    })}
  >
    {(title || description || text) && (
      <div className="mr-auto">
        <BlocksTitle title={title} description={description} text={text} />
      </div>
    )}
    {link && (
      <a
        data-test="product-row-heading-link"
        href={link.href}
        className="ml-4 flex flex-shrink-0 items-center self-end text-blue"
        onClick={() => onLinkClick && onLinkClick(link.label)}
      >
        {link.label}
        <ArrowRight className="ml-1 inline-block" />
      </a>
    )}
  </div>
);
